import React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import BlogFooterAuthor from '../components/blog/blogFooterAuthor'
import NextPostCard from '../components/blog/nextPostCard'
import TrialCTA from "../components/trialCta";
import merchantOfferView from '../../public/Merchant-Offer-View.svg'

export const query = graphql`
query ($slug: String!) {
    site {
      siteMetadata {
        blog {
          title
          description
        }
      }
    }
    ghostPost (slug: {eq: $slug}) {
        slug
    }
    allGhostPost {
      edges {
        node {
          id
          reading_time
          title
          primary_author {
            name
            profile_image
            slug
            bio
            twitter
            facebook
            website
          }
          excerpt
          slug
          custom_excerpt
          primary_tag {
            name
            slug
          }
          published_at(formatString: "MMMM Do, YYYY")
          feature_image
          meta_title
          meta_description
          og_title
          og_image
          og_description
          twitter_title
          twitter_image
          twitter_description
          html
        }
        next {
            id
            title
            feature_image
            primary_tag {
                slug
                name
            }
            primary_author {
                slug
                name
            }
            slug
            published_at(formatString: "MMMM Do, YYYY")
            reading_time
        }
        previous {
            id
            title
            feature_image
            primary_tag {
                slug
                name
            }
            primary_author {
                slug
                name
            }
            slug
            published_at(formatString: "MMMM Do, YYYY")
            reading_time
        }
      }
    }
  }
`

const BlogPost = (props) => {
    const hrefLocation = props.data.ghostPost.slug;
    const postObject = props.data.allGhostPost.edges.find(hash => hash.node.slug === hrefLocation);

    const renderNextPostCard = (postObject) => {
        if (postObject.next.slug !== "data-schema") {
            return(
                <NextPostCard props={postObject.next} />
            )
        }
    }

    const renderPreviousPostCard = (postObject) => {
        if (postObject.previous.slug !== "data-schema") {
            return(
                <NextPostCard props={postObject.previous} />
            )
        }
    }

    return (
        <>
        <Helmet>
            {/* Meta */}
            <html lang="en" />
            <title>{postObject.node.title} — {props.data.site.siteMetadata.blog.title}</title>
            <meta charSet="utf-8" />
            <meta name="title" content={postObject.node.title}/>
            <meta name="description" content={postObject.node.custom_excerpt} /> 
            <link rel="canonical" href={`https://www.sellwithbatch.com/${postObject.node.slug}`} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="article" />
            <meta property="og:url" content={`https://sellwithbatch.com/${postObject.node.slug}`} />
            <meta property="og:title" content={postObject.node.title} />
            <meta property="og:description" content={postObject.node.custom_excerpt} />
            <meta property="og:image" content={postObject.node.feature_image}  />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={`https://sellwithbatch.com/${postObject.node.slug}`} />
            <meta property="twitter:title" content={postObject.node.title} />
            <meta property="twitter:description" content={postObject.node.custom_excerpt} />
            <meta property="twitter:image" content={postObject.node.feature_image}/>
        </Helmet>
        <Layout>
                <div className="relative py-2 overflow-hidden bg-white md:pt-16">
                    <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                        <div className="relative h-full mx-auto text-lg max-w-prose">
                        <svg className="absolute transform translate-x-32 top-12 left-full" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                            </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                            </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg className="absolute transform translate-x-32 bottom-12 left-full" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                            </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                        </svg>
                        </div>
                    </div>
                    <article className="relative px-4 sm:px-6 lg:px-8">
                        <div className="w-full mb-4 bg-center bg-cover rounded blog-title-height" style={{"background-image": `url(${postObject.node.feature_image})`}} alt={`${postObject.node.custom_excerpt}`} />

                        <div className="mx-auto mb-6 text-lg max-w-prose">

                            <Link to={`/${postObject.node.primary_tag.slug}`}>
                                <span className="inline-flex items-center px-1.5 py-0.5 text-sm font-medium leading-5 bg-indigo-700 text-white">
                                    {postObject.node.primary_tag.name.toUpperCase()}
                                </span>
                            </Link>

                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 my-7 leading-12 sm:text-5xl sm:leading-tight">{postObject.node.title}</h1>

                            <div id={`post-header-${postObject.node.id}`} className="flex items-center mb-6">
                                <div>
                                    <span className="flex text-sm leading-5 text-gray-500">
                                        <span className="mx-1">by </span>
                                        <span className="text-sm font-medium leading-5 text-purple-500">
                                            <Link to={`/authors/${postObject.node.primary_author.slug}`} className="hover:underline">
                                                {postObject.node.primary_author.name}
                                            </Link>
                                        </span>
                                        <span className="mx-1">
                                            &middot;
                                        </span>
                                        <time datetime={postObject.node.published_at}>
                                            {postObject.node.published_at}
                                        </time>
                                        <span className="mx-1">
                                            &middot;
                                        </span>
                                        <span>
                                            {postObject.node.reading_time} min read
                                        </span>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                        <div className="mx-auto prose prose-lg text-gray-500 " dangerouslySetInnerHTML={{__html: postObject.node.html}} />
                        <div className="w-3/5 pb-16 mx-auto leading-8 border border-t-0 border-b border-l-0 border-r-0 border-indigo-100" />

                        <BlogFooterAuthor props={postObject.node.primary_author}/>
                    </article>

                    <div className="flex justify-center pt-8 space-x-6">
                        <div className="flex-col w-1/3">
                            { (postObject.previous !== null) ? renderPreviousPostCard(postObject) : "" }
                        </div>
                        <div className="flex-col w-1/3">
                            { (postObject.next !== null) ? renderNextPostCard(postObject) : "" }
                        </div>
                    </div>
                </div>
                <TrialCTA 
                    bgClass={"bg-indigo-600"}
                    title={"Auction Marketing for Shopify Retailers"} 
                    description={"Collect an unlimited number of offers and activate them for immediate revenue on the Forever Free plan."}
                    buttonText={"Install Batch for free"} 
                    url={"https://apps.shopify.com/batch-offers"} 
                    image={merchantOfferView}
                />
        </Layout>
        </>
    )
}

export default BlogPost
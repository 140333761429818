import React from 'react'
import { Link } from 'gatsby'
import TwitterIcon from '../twitterIcon'
import FacebookIcon from '../facebookIcon'
import LinkIcon from '../linkIcon'

const BlogFooterAuthor = (props) => {
    return(
        <div className="pt-12 ">
            <div className="flex justify-center">
                <Link to={`/authors/${props.props.slug}`}>
                    <img className="w-20 h-20 rounded-full" src={props.props.profile_image} alt=""/>
                </Link>
            </div>
            <h2 className="flex justify-center mt-4 text-2xl font-bold">
                {props.props.name}
            </h2>
            <p className="mt-4 font-light leading-3 text-center text-gray-500">
                {props.props.bio}
            </p>
            <p className="flex justify-center mt-4">
                <FacebookIcon props={props.props} />
                <TwitterIcon props={props.props} />
                <LinkIcon props={props.props} />
            </p>
        </div>
    )
}

export default BlogFooterAuthor
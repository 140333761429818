import React from 'react'
import { Link } from 'gatsby'

const NextPostCard = (props) => {

    return (
        <div>
            <Link to={`/${props.props.slug}`}>
                <div className="bg-transparent blog-next-article">
                    <div className="h-64 min-w-full p-2 bg-center bg-cover rounded-lg opacity-99" style={{"background-image": `url(${props.props.feature_image})`}} alt={`Next post: ${props.props.title}`}>
                        <Link to={`/${props.props.primary_tag.slug}`}>
                            <span className="inline-flex items-center px-1.5 py-0.5 text-sm font-medium leading-5 bg-indigo-700 text-white">
                                {props.props.primary_tag.name.toUpperCase()}
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex-col">
                        <h3 className="mt-2 text-xl font-semibold opacity-99">
                            <Link to={`/${props.props.slug}`}>{props.props.title}</Link>
                        </h3>
                    </div>
                </div>
            </Link>

            <div id={`post-header-${props.props.id}`} className="flex items-center mb-6 opacity-99">
                <div>
                    <span className="flex text-sm leading-5 text-gray-500">
                        <span className="mr-1">by </span>
                        <span className="text-sm font-medium leading-5 text-purple-500">
                            <Link to={`/authors/${props.props.primary_author.slug}`} className="hover:underline">
                                {props.props.primary_author.name}
                            </Link>
                        </span>
                        <span className="mx-1">
                            &middot;
                        </span>
                        <time datetime={props.props.published_at}>
                            {props.props.published_at}
                        </time>
                        <span className="mx-1">
                            &middot;
                        </span>
                        <span>
                            {props.props.reading_time} min read
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default NextPostCard